import styled from "styled-components";

export const Genres = styled.div`
  display: flex;
  glex-direction: row;
`;

export const Text = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  a:hover {
    text-decoration: underline;
  }

  &.sep {
    padding-right: 4px;
  }

  &.gray {
    color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  }
`;

export const ArtistAndGenres = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
`;

export const Name = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;

  white-space: nowrap;
  overflow: hidden;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  &:hover {
    text-decoration: underline;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const Meta = styled.div`
  position: absolute;
  width: 100%;
  height: 148px;
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: fles-start;
  justify-content: flex-start;
  transition: top 0.2s ease-in-out;
  overflow: hidden;
  padding: 8px 16px;
  gap: 4px;
  background: rgba(0, 0, 0, 0.8);

  @media ${(props) => props.theme.device.lg} {
    visibility: hidden;
    opacity: 0;
  }
`;

export const CardButton = styled.a`
  cursor: pointer;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  cursor: pointer;
`;

export const CardControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  border-top: 1px solid ${(props) => props.theme.colors.neutrals.primary[800]};
  z-index: 10;

  @media ${(props) => props.theme.device.lg} {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  display: block;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ImagePlaceholder = styled.div<{ aspectRatio: number; maxWidth: number }>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 100%;
  background: ${(props) => props.theme.colors.neutrals.primary[750]};
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.actions ${Meta} {
    top: calc(100% - 148px);
    visibility: visible;
    opacity: 1;
  }

  @media ${(props) => props.theme.device.lg} {
    &:hover {
      ${Meta} {
        top: calc(100% - 108px);
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;
