import { HeadingH1 } from "@components/typography/Typography.style";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled(HeadingH1)`
  font-size: ${(props) => props.theme.fontSizes[8].size};
`;
