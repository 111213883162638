import { Chart } from "@models/Chart";
import { PageModuleItem } from "@models/PageModule";
import { GridSlider } from "../GridSlider";

interface Props {
	title: string;
	data: PageModuleItem[];
	rows?: number;
	dataTestId?: string;
	location?: string;
}

const PageModuleChartsGrid: React.FC<Props> = ({
	title,
	data,
	rows = 2,
	dataTestId,
	location,
}) => {
	if (data.length === 0) return <></>;
	const filteredData = data
		.filter(
			(item) => item.item && item.item_type && item.item_type.name === "chart",
		)
		.map((item) => {
			return item.item as unknown as Chart;
		});

	return (
		<GridSlider
			location={location}
			type="charts"
			rows={rows}
			title={title}
			data={filteredData || []}
			dataTestId={dataTestId}
		/>
	);
};

export default PageModuleChartsGrid;
