import { Chart } from "@models/Chart";
import Image from "next/image";
import Link from "next/link";
import { ImageWrapper, Name, Overlay, Wrapper } from "./HeroChartCompact.style";

// TODO implement proper Chart interface
interface Props {
	chart: Chart;
	dataTestId?: string;
	handleProductClick?: () => void;
}

const HeroChartCompact: React.FC<Props> = ({
	chart,
	dataTestId,
	handleProductClick,
}) => {
	return (
		<Wrapper>
			<Link
				href={`/chart/${chart.slug}/${chart.id}`}
				prefetch={false}
				title={chart.name}
				className="artwork"
				data-testid={dataTestId}
				onClick={() => handleProductClick && handleProductClick()}
			>
				<Overlay />
				<Name>{chart.artist?.name || chart.person.owner_name}</Name>
				<ImageWrapper>
					<Image
						src={chart.image.uri}
						alt={chart.name}
						width={350}
						height={196}
						sizes="100vw"
						style={{
							width: "100%",
							height: "auto",
						}}
					/>
				</ImageWrapper>
			</Link>
		</Wrapper>
	);
};

export default HeroChartCompact;
