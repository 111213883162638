import { HeroChart } from "@components/cards/HeroChart";
import { HeroImage } from "@components/cards/HeroImage";
import { HeroRelease } from "@components/cards/HeroRelease";
import { Carousel } from "@components/core/Carousel";
import {
	dataLayerChartImpression,
	dataLayerReleaseImpression,
	pushProductClickEvent,
	pushProductImpressionEvent,
} from "@lib/utils/dataLayer";
import { Chart } from "@models/Chart";
import { PageModuleItem } from "@models/PageModule";
import { Release } from "@models/release";
import { useCallback, useEffect } from "react";
import { Title, Wrapper } from "./PageModuleHeroSlider.style";

interface Props {
	title?: string;
	data?: PageModuleItem[];
	negativeMargin: boolean;
	dataTestId?: string;
	bannerSizing?: {
		maxWidth: number;
		aspectRatio: number;
	};
}

const PageModuleHeroSlider: React.FC<Props> = ({
	title,
	data = [],
	negativeMargin,
	dataTestId,
	bannerSizing,
}) => {
	const getReleaseProductEventData = useCallback(dataLayerReleaseImpression, [
		title,
	]);
	const getChartProductEventData = useCallback(dataLayerChartImpression, [
		title,
	]);

	const isRelease = (item: PageModuleItem) =>
		item.item && item.item_type && item.item_type.name === "release";

	const isChart = (item: PageModuleItem) =>
		item.item && item.item_type && item.item_type.name === "chart";

	useEffect(() => {
		if (data && data.length > 0) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: (data[0] as unknown as Release).price?.code || "",
					impressions: data
						.map((item, index) => {
							if (isRelease(item)) {
								return getReleaseProductEventData({ release: item.item as unknown as Release,
									index,
									location: title,
								});
							}
							if (isChart(item)) {
								return getChartProductEventData({
									item: item.item as unknown as Chart,
									index,
									location: title,
								});
							}
							return null;
						})
						.filter((item) => item !== null),
				},
			});
		}
	}, []);

	const handleProductClick = (item: PageModuleItem, index: number) => {
		if (isRelease(item)) {
			pushProductClickEvent({
				ecommerce: {
					currencyCode: (item.item as unknown as Release).price?.code || "",
					click: {
						actionField: {
							list: title || "",
						},
						products: [
							getReleaseProductEventData({
								release: item.item as unknown as Release,
								index,
							}),
						],
					},
				},
			});
		} else if (isChart(item)) {
			pushProductClickEvent({
				ecommerce: {
					currencyCode: (item.item as unknown as Chart).price?.code || "",
					click: {
						actionField: {
							list: title || "",
						},
						products: [
							getChartProductEventData({ item: item.item as unknown as Chart, index }),
						],
					},
				},
			});
		}
	};

	return (
		<Wrapper className="hero-slider">
			<Carousel
				title={title ? <Title>{title}</Title> : undefined}
				negativeMargin={negativeMargin}
				showDots={data && data.length > 1}
				showNavArrows={data && data.length > 1}
				loop={data && data.length > 1}
			>
				{data &&
					data.map((item, index) => {
						if (isRelease(item)) {
							return (
								<HeroRelease
									key={`page-module-hero-release-${item.id}`}
									title={title}
									release={item.item as unknown as Release}
									overrideImage={item.image?.uri}
									dataTestId={dataTestId}
									handleProductClick={() => handleProductClick(item, index)}
									bannerSizing={bannerSizing}
								/>
							);
						} else if (isChart(item)) {
							return (
								<HeroChart
									key={`page-module-hero-chart-${item.id}`}
									chart={item.item as unknown as Chart}
									overrideImage={item.image?.uri || undefined}
									dataTestId={dataTestId}
									bannerSizing={bannerSizing}
									handleProductClick={() => handleProductClick(item, index)}
								/>
							);
						} else if (item.image && item.image) {
							return (
								<HeroImage
									key={`page-module-hero-image-${item.id}`}
									image={item.image?.uri || ""}
									link={item.external_url}
									dataTestId={dataTestId}
								/>
							);
						}
					})}
			</Carousel>
		</Wrapper>
	);
};

export default PageModuleHeroSlider;
