import { API_BASES } from "@lib/constants";
import { getRequest } from "@lib/network/request";
import { LocationParams } from "@models/facets";
const urlPayload = (params: any) => new URLSearchParams(params);

export const getCurationPagesRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: CurationPageParams;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/curation/pages/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getCurationCompositePageRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/curation/pages/${id}/?composite=page_module_items`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getPageModulesRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: PageModuleParams;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "/curation/page-modules/?" + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getPageModuleItemsRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/curation/page-modules/${id}/items/`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getPageModulesQuery = ({
	params,
	accessToken,
	location,
}: {
	params: PageModuleParams;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`page-modules-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getPageModulesRequest({
			params,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getPageModuleItemsQuery = ({
	id,
	accessToken,
	location,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`page-module-${id}-items`],
	queryFn: async () => {
		const { data } = await getPageModuleItemsRequest({
			id,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getCurationPagesQuery = ({
	params,
	accessToken,
	location,
}: {
	params: CurationPageParams;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`curration-pages-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getCurationPagesRequest({
			params,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getCurationCompositePageQuery = ({
	id,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	accessToken?: string;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: [`curration-composite-page-${id}`],
	queryFn: async () => {
		const { data } = await getCurationCompositePageRequest({
			id,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});
