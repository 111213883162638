import Image from "next/image";
import Link from "next/link";

interface Props {
	link?: string;
	image: string;
	title?: string;
	dataTestId?: string;
	handleProductClick?: () => void;
}

const HeroImage: React.FC<Props> = ({
	link,
	image,
	title,
	dataTestId,
	handleProductClick,
}) => {
	return (
		<div className="hero-image">
			{link ?
					(
						<Link
							href={`${link}`}
							prefetch={false}
							data-testid={dataTestId}
							onClick={() => handleProductClick && handleProductClick()}
						>
							<Image
								src={image}
								alt={title ?? ""}
								width="732"
								height="354"
								style={{
									height: "auto",
								}}
							/>
						</Link>
					) :
					(
						<Image
							src={image}
							alt={title ?? ""}
							width="732"
							height="354"
							style={{
								height: "auto",
							}}
						/>
					)}
		</div>
	);
};

export default HeroImage;
