import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import { renderArtistNames } from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { cls } from "@lib/css";
import { Release } from "@models/release";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import {
	Actions,
	Artists,
	ArtistsAndLabel,
	Badges,
	CardButton,
	CardControls,
	ImagePlaceholder,
	ImageWrapper,
	Label,
	Meta,
	ReleaseName,
	Wrapper,
} from "./HeroRelease.style";

interface Props {
	release: Release;
	overrideImage?: string;
	dataTestId?: string;
	title?: string;
	handleProductClick?: () => void;
	bannerSizing?: {
		maxWidth: number;
		aspectRatio: number;
	};
}

const HeroRelease: React.FC<Props> = ({
	release,
	overrideImage,
	dataTestId,
	handleProductClick,
	title,
	bannerSizing,
}) => {
	const [controls, setControls] = useState<boolean>(false);
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;
	const preOrder = `${t("PreOrder")}`;

	return (
		<Wrapper
			className={cls("hero-release", controls ? "actions" : undefined)}
			data-testid={dataTestId}
		>
			<ImageWrapper>
				{(release.exclusive || release.pre_order || release.is_hype) && (
					<Badges>
						{release.exclusive && <div className="exclusive">{exclusive.toUpperCase()}</div>}
						{release.pre_order && <div className="preorder">{preOrder.toUpperCase()}</div>}
						{release.is_hype && <div className="hype">HYPE</div>}
					</Badges>
				)}
				<Link
					href={`/release/${release?.slug}/${release?.id}`}
					prefetch={false}
					title={release?.name}
					className="artwork"
					onClick={() => handleProductClick && handleProductClick()}
				>
					{
						bannerSizing && <ImagePlaceholder $maxWidth={bannerSizing.maxWidth} $aspectRatio={bannerSizing.aspectRatio} />
					}
					<Image
						src={overrideImage ? overrideImage : release?.image?.uri || ""}
						alt={release?.name}
						width={0}
						height={0}
						sizes="100vw"
						priority
						style={{
							width: "100%",
							height: "auto",
						}}
					/>
				</Link>
			</ImageWrapper>
			<Meta>
				<Link
					href={`/release/${release?.slug}/${release?.id}`}
					prefetch={false}
					title={release?.name}
					className="artwork"
				>
					<ReleaseName>{release?.name}</ReleaseName>
				</Link>
				<ArtistsAndLabel>
					{release.artists && release.artists.length > 0 && (
						<Artists>
							{renderArtistNames(release.artists, { location: title })}
						</Artists>
					)}
					<Label>
						<LabelLink
							label={release?.label}
							location={title}
							className="artwork"
							withMarquee
						/>
					</Label>
				</ArtistsAndLabel>
				<Actions>
					<Play variant="rounded" releaseId={release.id} />
					<AddToQueue releaseId={release.id} />
					<AddToPlaylist releaseId={release.id} />
					<AddToCart release={release} />
				</Actions>
			</Meta>
			<CardControls>
				<CardButton
					onClick={() => {
						setControls(!controls);
					}}
				>
					<SpriteIcon id="dots" width="32" height="32" />
				</CardButton>
			</CardControls>
		</Wrapper>
	);
};

export default HeroRelease;
