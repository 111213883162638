import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import { cls } from "@lib/css";
import { Chart } from "@models/Chart";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import {
	Actions,
	ArtistAndGenres,
	CardButton,
	CardControls,
	Genres,
	ImagePlaceholder,
	ImageWrapper,
	Meta,
	Name,
	Text,
	Wrapper,
} from "./HeroChart.style";

interface Props {
	chart: Chart;
	overrideImage?: string;
	dataTestId?: string;
	handleProductClick?: () => void;
	bannerSizing?: {
		maxWidth: number;
		aspectRatio: number;
	};
}

const HeroChart: React.FC<Props> = ({
	chart,
	overrideImage,
	dataTestId,
	handleProductClick,
	bannerSizing,
}) => {
	const [controls, setControls] = useState<boolean>(false);

	return (
		<Wrapper className={cls("hero-chart", controls ? "actions" : undefined)}>
			<ImageWrapper>
				<Link
					href={`/chart/${chart.slug}/${chart.id}`}
					prefetch={false}
					title={chart.name}
					className="artwork"
					data-testid={dataTestId}
					onClick={() => handleProductClick && handleProductClick()}
				>
					{bannerSizing && <ImagePlaceholder {...bannerSizing} />}
					<Image
						src={overrideImage ? overrideImage : chart.image?.uri}
						alt={chart.name}
						width={0}
						height={0}
						sizes="100vw"
						style={{
							width: "100%",
							height: "auto",
						}}
					/>
				</Link>
			</ImageWrapper>
			<Meta>
				<Link
					href={`/chart/${chart.slug}/${chart.id}`}
					prefetch={false}
					title={chart.name}
					className="artwork"
				>
					<Name>{chart.name}</Name>
				</Link>
				<ArtistAndGenres>
					{chart.artist && (
						<Text>
							<Link
								href={`/artist/${chart.artist.slug}/${chart.artist.id}`}
								prefetch={false}
								title={chart.artist.name}
							>
								{chart.artist.name}
							</Link>
						</Text>
					)}
					<Text className="gray">
						|
						{" "}
						{chart.add_date.includes("T") ?
							chart.add_date.split("T")[0] :
							chart.add_date}
						{" "}
						|
					</Text>
					<Genres>
						{chart.genres && chart.genres.length > 0 && chart.genres
							.map((g) => (
								<Text key={`chart-genre-${g.id}`}>
									<Link
										href={`/genre/${g.slug}/${g.id}`}
										prefetch={false}
										title={g.name}
									>
										{g.name}
									</Link>
								</Text>
							))
							.reduce(
								(prev, curr) => (
									<>
										{prev}
										<Text className="sep">,</Text>
										{" "}
										{curr}
									</>
								), <></>,
							)}
					</Genres>
				</ArtistAndGenres>
				<Actions>
					<Play variant="rounded" chartId={chart.id} />
					<AddToQueue chartId={chart.id} />
					<AddToPlaylist chartId={chart.id} />
					<AddToCart chart={chart} />
				</Actions>
			</Meta>
			<CardControls>
				<CardButton
					onClick={() => {
						setControls(!controls);
					}}
				>
					<SpriteIcon id="dots" width="32" height="32" />
				</CardButton>
			</CardControls>
		</Wrapper>
	);
};

export default HeroChart;
