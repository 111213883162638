import { HeroImage } from "@components/cards/HeroImage";
import { Carousel } from "@components/core/Carousel";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { PageModuleItem } from "@models/PageModule";
import { device } from "@styles/theme";
import { HeroWrapper, Wrapper } from "./PageModulePromotions.style";

export interface Props {
	data: PageModuleItem[];
	dataTestId?: string;
}

const PageModulePromotions: React.FC<Props> = ({ data, dataTestId }) => {
	// we use device width to determine chunk size
	const isMd = useMediaQuery({ query: device.md });
	const isSm = useMediaQuery({ query: device.sm });
	const chunkSize = isSm && !isMd ? 2 : 1;
	const chunked: Array<PageModuleItem[]> = [];
	for (let i = 0; i < data.length; i += chunkSize) {
		const chunk = data.slice(i, i + chunkSize);
		chunked.push(chunk);
	}

	return (
		<Wrapper data-testid={`${dataTestId}-wrapper`}>
			<Carousel title=" " showDots showRightNav>
				{chunked.map((items, index) => (
					<HeroWrapper key={`promotions-wrapper-${index}`}>
						{items.map(({
							image,
							external_url,
							item_type,
							item,
						}) => {
							if (
								(image?.uri && external_url !== "") ||
								(item_type?.name === "chart")
							) {
								const imageUri = image?.uri || item?.image?.uri || "";
								const link = external_url || `/chart/${item?.slug}/${item?.id}`;

								return (
									<HeroImage
										key={`promotions-page-module-hero-image-${item?.id}`}
										image={imageUri}
										link={link}
										dataTestId={dataTestId}
									/>
								);
							}
						})}
					</HeroWrapper>
				))}
			</Carousel>
		</Wrapper>
	);
};

export default PageModulePromotions;
