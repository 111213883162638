import styled from "styled-components";

export const Name = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  position: absolute;
  bottom: 0px;
  padding: 16px;

  z-index: 2;

  @media ${(props) => props.theme.device.xl} {
    font-size: 16px;
    line-height: 16px;

    bottom: initial;
    width: 100%;
    aspect-ratio: 17.3/9;
    display: flex;
    align-items: end;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #000 0, transparent 50%);
  z-index: 1;

  @media ${(props) => props.theme.device.xl} {
    height: initial;
    aspect-ratio: 16/9;
  }
`;

export const ImageWrapper = styled.div`
  img {
    width: 100%;
  }
  @media ${(props) => props.theme.device.xl} {
    position: relative;
    top: -35px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  @media ${(props) => props.theme.device.xl} {
    aspect-ratio: 17.3/9;
  }
`;
